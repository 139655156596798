import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductionLocationList() {
  // Use toast
  const toast = useToast()
  const refProductionLocationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', label: 'Type', sortable: true },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProductionLocations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const search = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refProductionLocationListTable.value ? refProductionLocationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalProductionLocations.value,
    }
  })

  const refetchData = () => {
    refProductionLocationListTable.value.refresh()
    GetProductionLocations()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetProductionLocations() {
    store.dispatch('pamis-productionLocation/fetchProductionLocations')
      .then(response => {
        const productionLocationResult = response.data.filter(x=>x.isDelete == false);
        row.value = productionLocationResult;
        totalProductionLocations.value = productionLocationResult.length;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Production Location list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  GetProductionLocations()


  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalProductionLocations,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refProductionLocationListTable,
    refetchData
  }
}
