<template>
  <b-sidebar
    id="add-new-productionLocation-sidebar"
    :visible="isAddNewProductionLocationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-production-location-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Production Location Type" : actionType == 1 ? " Update Production Location Type" : "Delete Production Location Type"}}
         
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Production Location Type"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="currentProductionLocation.name"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Production Location Type"
              />
              </b-form-group>

              <b-form-checkbox 
                v-if="currentProductionLocation!=null&& currentProductionLocation.id!=null"
                class="mt-2"
                :disabled="actionType==2"
                id="input-isActive"
                v-model="currentProductionLocation.isActive"
              >
                IsActive
              </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            
          </validation-provider>
     
          <!-- <category-selector :initial-id="currentCategory==null||currentCategory.upperCategory==null?null:currentCategory.upperCategory"  @selectionChanged="selectionChanged" ></category-selector> -->
       
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentProductionLocation==null|| currentProductionLocation.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentProductionLocation!=null&& currentProductionLocation.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteProductionLocation"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
// import CategorySelector from '../components/SingleCategorySelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // CategorySelector
  },
   methods:{
    deleteProductionLocation(){
       this.$store.dispatch('pamis-productionLocation/deleteProductionLocation', {id : this.currentProductionLocation.id})
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-location-sidebar-active', false)
          this.notification()
        });
    },

    submitForm(){
      var process = "pamis-productionLocation/addProductionLocation";

      if(this.currentProductionLocation !=null &&  this.currentProductionLocation.id != null){
        process = "pamis-productionLocation/updateProductionLocation";
      } 
        
       this.$store.dispatch(process, this.currentProductionLocation)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-production-location-sidebar-active', false)
            this.notification()
        });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
  
  watch: {
     productionLocationdata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined)
          {
            this.currentProductionLocation={
              Id:null,
              name:null,
              clientId:null,
            }
          } else
          { 
            this.currentProductionLocation = n.selectedProductionLocation;
          }
        },
        deep: true
    }
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductionLocationSidebarActive',
    event: 'update:is-add-new-production-location-sidebar-active',
  },
  props: {
    isAddNewProductionLocationSidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentProductionLocation:{
        Id:null,
        Name:null,
        clientId:null,
      },
      client:null,
    }
  },
   
  inject: ['productionLocationdata'],

  setup(props,  { emit }) {
    const blankProductionLocationData = {
      id:null,
      name:null,
      clientId:null
    }

    const productionLocationData = ref(JSON.parse(JSON.stringify(blankProductionLocationData)))
    const resetProductionLocationData = () => {
      productionLocationData.value = JSON.parse(JSON.stringify(blankProductionLocationData))
      emit('refetch-data')
      emit('update:is-add-new-production-location-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-productionLocation/addProductionLocation', currentProductionLocation)
        .then(() => {

        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProductionLocationData)

    return {
      productionLocationData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-productionLocation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
