import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductionLocations(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/ProductionLocationType')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    addProductionLocation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/ProductionLocationType/Create',  data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductionLocation(ctx, { id }) {
       return new Promise((resolve, reject) => {
         axios
           .delete('pamis/admin/organization/ProductionLocationType/Delete?id='+id)
           .then(response => resolve(response))
           .catch(error => reject(error))
       })
     },
     updateProductionLocation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/organization/ProductionLocationType/Update?id='+data.id, data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
